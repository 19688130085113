export enum MoviesActions {
	FETCH_MOVIES = 'MOVIES_FETCH_MOVIES',
	LOAD_MORE_MOVIES = 'MOVIES_LOAD_MORE_MOVIES',

	ADD_MOVIES = 'MOVIES_ADD_MOVIES',
	SET_MOVIES = 'MOVIES_SET_MOVIES',
	RESET_PAGE = 'MOVIES_RESET_PAGE',
	SET_LOADING = 'MOVIES_SET_LOADING',

	OPEN_DETAILED = 'MOVIES_OPEN_DETAILED',
	CLOSE_DETAILED = 'MOVIES_CLOSE_DETAILED',

	SET_DETAILED_LOADING = 'MOVIES_SET_DETAILED_LOADING',
	SET_DETAILED_MOVIE = 'MOVIES_SET_DETAILED_MOVIE'
}
